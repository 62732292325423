<template>
  <el-select
      :id="id"
      class="f-full-width"
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      :disabled="disabled"
      :clearable="clearable"
      ref="fselect"
      placeholder="请选择"
      @visible-change="visibleChange"
      @focus="selectFocus"
      filterable
      v-el-select-loadmore="loadmore"
      @change="handleChange"
      @click.native="handleGetData(true)"
      :filter-method="filterList"
      v-model="inputVal"
      :isNumber="isNumber"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      @clear="blurForBug"
      @hook:mounted="cancalReadOnly">
    <div class="flex_end_center" v-if="isAll">
      <el-checkbox class=" f-m-r-20" v-model="checked" @change='selectAll'>全选</el-checkbox>
    </div>
    <el-option
        v-for="(item,index) in options"
        :key="item.value + '-' + index"
        :label="item.text || item.label || item.itemText"
        :value="item.value || item.itemValue"
        :disabled="item.isDisabled || item.disabled || item.isEnabled === '0' || item.isShow === '0' || false"
        style="max-width: 790px">
    </el-option>
  </el-select>
</template>
<!--        :filter-method="filterList"
        v-model="inputVal"-->
<script>
import {getAction} from '@/api/manage'

export default {
  name: 'FSelect',
  props: {
    value: {
      type: Number | String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => [],
    },
    dict: {
      type: String | Array,
      default: () => [],
    },
    removeValue: {
      type: String | Number,
      default: ''
    },
    isNeed: {
      type: String | Number,
      default: ''
    },
    clearSelect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    fatherVal: {
      type: String | Array,
      default: '',
    },
    // 是否支持多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 选项禁用的条件，支持多个,注意要传递value的值
    disabledCondition: {
      type: Array,
      default: () => []
    },
    // 多选的初始值实现初始值无法删除的效果，注意要传递value的值
    initMultipleList: {
      type: Array,
      default: () => []
    },
    //判断是否是数值
    isNumber: {
      type: Boolean,
      default: false
    },
    //判断是否是数值
    isOne: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    dictSize: {
      type: Number,
      default: 0
    },
    //是否需要 slice 减少 搜索之后的 下拉框 options 数量
    isSuo: {
      type: Boolean,
      default: false
    },
    showSize: {
      type: Number,
      default: 0
    },
    getDictUrl: {
      type: String,
      default: ''
    },
    getDictParams:{
      type: Object,
      default: ()=>{}
    },
    // 是否显示全选
    isAll: {
      type: Boolean,
      default: false
    },
    // 多选时是否将选中值按文字的形式展示
    collapseTags: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formData: {
        pageIndex: 1,
        pageSize: 600
      },
      allOptions: [],
      options: [],

      url: {
        dict: '/sys/dict/getDictItems/'
      },
      changelabel: '',
      inputVal: '',
      aaa: true,
      checked: false,
      selectVale: '', //搜索框输入的值
    }
  },
  watch: {
    dict: {
      immediate: true,
      handler(val) {
        if (this.isNeed) {
          this.handleGetData()
        }

      }
    },
    checked(newVal) {
      let allOptionArr = this.allOptions;
      let keyWords = this.selectVale;
      if (this.multiple) {
        if (newVal) {
          // 根据 keyWords 过滤 allOptions
          let filteredOptions = allOptionArr;
          if (keyWords) {
            filteredOptions = allOptionArr.filter(item => {
              return item.itemText && item.itemText.toLowerCase().includes(keyWords.toLowerCase());
            });
          }
          // 全选时，只选择过滤后的选项
          this.inputVal = filteredOptions.map(option => option.value);
        } else {
          // 取消全选
          this.inputVal = [];
        }
        this.$emit('input', this.inputVal);
      }
    },

    // fatherVal:{
    //     immediate:true,
    //     handler(newV){
    //         if (newV && newV.length>0){
    //
    //             this.$set(this,'inputVal',newV);
    //             this.nipeima();
    //             this.$emit('change',newV);
    //         } else {
    //             this.inputVal = '';
    //         }
    //     },
    // },
    isNeed: {
      immediate: true,
      handler(newV) {
        if (newV && newV.length > 0) {
          this.handleGetData();
          this.theEcho(this.isNeed);
          this.$emit('change', newV);
        } else {
          this.$set(this, 'inputVal', '');
        }
      },
    },
    initMultipleList: {
      immediate: true,
      handler(newV) {
        if (newV && newV.length > 0) {
          this.$nextTick(() => {
            this.cannotDeleteMultiple();
          })
        }
      },
    },
    dataList:{
       immediate: true,
      deep: true,
      handler(newV) {
        if (newV && newV.length > 0 && !this.dict.length) {
          this.handleGetData();
        }
      },
    }

  },
  created() {
    // if (this.isNeed){
    //     this.handleGetData();
    // }
  },
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        );
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  mounted() {
    // let input = this.$refs['fselect'].$children[0].$refs['input'];
    // input.addEventListener('input',this.filterList(this.inputVal));
    // if (this.fatherVal && this.fatherVal.length>0){
    //     this.inputVal = this.fatherVal;
    // }
    // if (this.isNeed && this.isNeed.length>0){
    //     this.inputVal = this.isNeed;
    // }
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const {fselect} = this.$refs;
          const input = fselect.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
        }
      });
    },
    // 设置禁用的选项，赋值给allOptions
    getItemDisabled() {
      if (this.disabledCondition && this.disabledCondition.length > 0) {
        this.disabledCondition.forEach(item => {
          this.options.forEach(bothItem => {
            bothItem.isDisabled = item == bothItem.value ? true : false
          })
        })
      }
    },
    // 多选实现无法删除的效果
    cannotDeleteMultiple() {
      if (this.initMultipleList && this.initMultipleList.length > 0) {
        const indexs = []; // 需要隐藏删除的标签索引
        this.initMultipleList.forEach(initItem => {
          this.options.forEach((item, index) => {
            if (item.value === initItem) indexs.push(index)
          })
        })
        const tags = document.querySelectorAll('.el-tag__close')
        tags.forEach((el, index) => {
          if (indexs.includes(index) && ![...el.classList].includes('select-tag-close-none')) {
            el.classList.add('dsplay_none_close')
          }
        })
      }
    },
    // 解决打开选择框的情况下清除按钮不起作用的原生bug
    blurForBug() {
      // 多选无法找到input，直接不作处理
      if (this.multiple === true) {
        return;
      }
      if (this.$refs['fselect'] && this.$refs['fselect'].$children.length > 0 && this.$refs['fselect'].$children[0].$refs['input']) {
        let input = this.$refs['fselect'].$children[0].$refs['input'];
        input.blur();
        input.value = '';
      }
    },

    loadmore() {
      if(this.selectVale && this.allOptions && (this.allOptions.length < this.formData.pageSize)) {
        return;
      }
      this.formData.pageIndex++;
      this.getMorePortlist(this.formData);
    },
    getMorePortlist(v) {
      let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
      this.options = this.allOptions.filter((item, index, arr) => {
        return index < num;
      });
    },
//下拉框截取
    filterList(val) {
      this.selectVale = val;
      const maxNum = 100;
      if (val) {
        this.options = this.allOptions.filter((item) => {

          if (item.itemText) {
            if (item.itemText.includes(val) || item.itemText.toUpperCase().includes(val.toUpperCase())) {
              return true;
            }
          }
          if (item.text) {
            if (item.text.includes(val) || item.text.toUpperCase().includes(val.toUpperCase())) {
              return true;
            }
          }
          if (item.label) {
            if (item.label.includes(val) || item.label.toUpperCase().includes(val.toUpperCase())) {
              return true;
            }
          }
        })
      } else {
        this.options = this.allOptions.slice(0, 600);
      }
      if (this.isSuo && this.options && this.options.length > maxNum) {
        this.options = this.options.splice(0, maxNum);
      }
      //如果是多选
      if (this.multiple == true) {

      } else {
        // this.inputVal = val;
      }


    },
    // 回显
    theEcho(val) {
      this.allOptions.filter((item) => {
        if (val == item.value) {
          return true;
        }
      })
      this.inputVal = val;
    },
    selectFocus(e) {
      // 多选无法找到input，直接不作处理
      if (this.multiple === true) {
        return;
      }
      let value = e.target.value || '';
      setTimeout(() => {
        let input = this.$refs['fselect'].$children[0].$refs['input'];
        input.value = value;
      });
    },
    visibleChange(val) {
      this.cancalReadOnly();
      // 多选无法找到input，直接不作处理
      if (this.multiple === true) {
        return;
      }
      if (!val) {
        let input = this.$refs['fselect'].$children[0].$refs['input'];
        input.blur();
      }
    },

    handleChange(val) {
      // 支持多选
      if (this.multiple === true) {
        let obj = [];
        val.forEach(element => {
          obj.push(this.allOptions.find(function (item) {
            return item.value === element;
          }))
        });
        if (obj.length) {
          this.changelabel = [];
          obj.forEach(element => {
            this.changelabel.push(element.text || element.title)
          })
        }
         this.$emit("changetAllData", obj);
      } else {
        let obj = this.allOptions.find(function (item) {
          return item.value === val;
        });
        if (obj) {
          this.changelabel = '';
          this.changelabel = obj.text || obj.title;
        }
         this.$emit("changetAllData", obj);
      }
      this.$emit('input', val);
      this.$emit('changet', this.changelabel);
      this.getItemDisabled();

    },


    listToMap(list, name) {
      var map = {};
      for (var index in list) {
        map[list[index][name]] = list[index];
      }
      return map;
    },
    parseText() {
      let optionsMap = this.listToMap(this.options, 'value');
      let allMap = this.listToMap(this.allOptions, 'value');
      if (this.isNeed) {
        let obj = optionsMap[this.isNeed];
        if (!obj) {
          if (allMap[this.isNeed]) {
            this.options.push(allMap[this.isNeed]);
          }

        }
      }
    },
    freshOptionsByRemoveValue() {
      if (this.removeValue) {
        this.options = this.options.filter(i => {
          return i.value !== this.removeValue;
        })
      }
    },
    // 全选
    // selectAll() {
    //   let allMap = this.listToMap(this.allOptions, 'value');
    //   this.$emit('selectAll', {checked: this.checked, dictMap: allMap})
    // },
    selectAll() {
      console.log("zxl")
      let allMap = this.listToMap(this.allOptions, 'value');
      let filteredOptions = this.allOptions.filter(item => {
        return (item.itemText && item.itemText.toLowerCase().includes(this.selectVale.toLowerCase())) ||
            (item.text && item.text.toLowerCase().includes(this.selectVale.toLowerCase())) ||
            (item.label && item.label.toLowerCase().includes(this.selectVale.toLowerCase()));
      });

      if (this.checked) {
        this.inputVal = filteredOptions.map(option => option.value);
      } else {
        this.inputVal = [];
      }

      this.$emit('selectAll', { checked: this.checked, dictMap: allMap });
      this.$emit('input', this.inputVal);
    },


    handleResetAll() {
      if(this.isAll) {
        this.checked = false;
      }
    },
    async handleGetData(flag) {
      if (this.dataList && this.dataList.length > 0 && Array.isArray(this.dataList)) {
        //默认显示 100条
        let size = 100;
        //这个传递的 是 需要展示的下拉框集合数量，不要乱传
        if (this.dictSize && this.dictSize >= size) {
          size = this.dictSize;
        }

        this.options = this.dataList.slice(0, size);
        this.allOptions = this.dataList;
        this.parseText();
        // this.$emit('getAllOptions', this.dataList);
        // this.$emit('getOptions', this.options);
      }
      if(this.clearSelect && this.dataList && this.dataList.length ==0 && Array.isArray(this.dataList)) {
        this.options = []
        this.allOptions = [];
      }
      //如果公共变量池里有 就不查询数据库
      else if (this.GLOBAL.dictListMap[this.dict] && this.GLOBAL.dictListMap[this.dict].length > 0 && Array.isArray(this.GLOBAL.dictListMap[this.dict])) {
        let dataList = this.GLOBAL.dictListMap[this.dict];
        this.options = dataList.slice(0, 600);
        this.allOptions = dataList;
        this.parseText();
        // this.$emit('getAllOptions', dataList);
        // this.$emit('getOptions', this.options);
      } else if (this.dict && this.dict.length > 0) {
        //页面一加载完成，读取sessionstorage的数据
        if (sessionStorage.getItem("dict:" + this.dict) || (this.$store.state.dict && this.$store.state.dict[this.dict] && Array.isArray(this.$store.state.dict[this.dict]))) {
          //存到前端页面缓存中
          this.allOptions = JSON.parse(sessionStorage.getItem("dict:" + this.dict)) || this.$store.state.dict[this.dict];

          this.options = this.allOptions.slice(0, 600);

          if (this.isOne && this.isOne.length > 0) {
            this.options = this.allOptions.filter(i => {
              return i.value === this.isOne;
            })
          }
          this.parseText();
          this.freshOptionsByRemoveValue();
        } else {
          await getAction(this.url.dict + this.dict + "?value=" + this.inputVal).then(res => {
            this.allOptions = res.result;

            this.options = this.allOptions.slice(0, 100)

            this.$emit('getAllOptions', this.allOptions);

            this.$emit('getOptions', this.options);
            //存到前端页面缓存中-大于1000条存储到store里面
            if (this.allOptions.length > 1000) {
              this.$store.commit('dict/setDict', {
                key: this.dict,
                value: this.allOptions
              })
            } else {
              sessionStorage.setItem("dict:" + this.dict, JSON.stringify(this.allOptions));
            }
            this.parseText();
            this.freshOptionsByRemoveValue();
          })
        }
      }
      if (this.getDictUrl && this.getDictUrl.length > 0 && flag) {
        getAction(this.getDictUrl,this.getDictParams)
            .then(res => {
              this.allOptions = res.result;
              this.options = this.allOptions.slice(0, 100);
              this.$emit('getAllOptions', this.allOptions);
              this.$emit('getOptions', this.options);
            })
      }

    }
  },
  //在页面刷新时将store存到页面缓存中
  beforeUpdate() {
  }
}
</script>

<style lang="scss">
.dsplay_none_close {
  display: none;
}
</style>

