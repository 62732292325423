<template>
    <div>
        <el-upload

            :class="{hideUpload:(hideUploadBtn || !isShowButton), 'isCustomPiceBox': isCustomPice}"
            :action="action"
            :file-list="fileList"
            :http-request="handleUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-exceed="onexceed"
            :on-change="handleEditChange"
            :limit="limit"
            v-bind="$attrs"
            :accept="accept"
            :disabled="disabled">
            <template slot="trigger" >
                <template v-if="this.$attrs.drag === '' || this.$attrs.drag === true">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </template>
                <template v-else>
                    <template v-if="this.$attrs['list-type'] === 'picture-card'" >
                        <i class="el-icon-plus"></i>
                    </template>
                    <el-button size="small" :type="buttonType" :disabled="disabled" v-else>{{disabled && isLoading ? '回显中，请稍后' : buttonTitle}}</el-button>
                </template>
            </template>
            <div slot="tip" class="el-upload__tip" v-if="tip">{{tip}}</div>
            <div slot="file" slot-scope="{file}" v-if="isCustomPice">
              <video v-if="getFileUrlType(file.url) == 'video'" :src="file.url" class="videoStyle" controls="controls" width="192px" height="108px">
                您的浏览器不支持视频播放
              </video>
              <img v-else
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
              >
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
        </el-upload>

        <el-dialog
            width="800px"
            title="预览"
            append-to-body
            :visible="visible"
            custom-class="c-custom-dialog"
            :before-close="() => { visible = false }">
            <img :src="imgUrl" width="100%" >
        </el-dialog>
    </div>
</template>

<script>
    import { postAction ,deleteAction } from '@/api/manage'

    import OssClientMixins from "../../../mixins/OssClientMixins";
    export default {

        name: 'FPlainUpload',
        inheritAttrs: false,
      mixins:[OssClientMixins],
        props: {
            action: {
                type: String,
                default: '/sys/oss/file/upload'
            },
            fileList: {
                type: Array,
                required: true
            },
            tip: {
                type: String
            },
            limit: {
                type: Number
            },
            baifang:{
                type:Object
            },
            dir:{
                type:String,
                default : 'osOrder'
            },
          plate:{
            type:String,
            default : 'fileUpload'
          },
            // 删除的时候是否要删除文件本身，默认是true，有特殊地方需要用到不删除文件本身，如信息中心-发现管理编辑附件、图片删除
            isDetail: {
              type: Boolean,
              default: true
            },
            disabled: {
              type: Boolean,
              default: false
            },
            isLoading: {
              type: Boolean,
              default: true
            },
            // 是否使用前端oss上传文件

            // 上传按钮类型默认是primary
            buttonType: {
              type: String,
              default: 'primary'
            },
            // 上传按钮文字
            buttonTitle: {
              type: String,
              default: '上传文件'
            },
            // 是否显示上传按钮
            isShowButton: {
              type: Boolean,
              default: true
            },
            // 接受上传的文件类型（thumbnail-mode 模式下此参数无效）
            accept: {
              type: String,
              default: ''
            },
            // 文件大小限制
            fileSizeLimit: {
              type: Number,
              default: 15
            },
            // 是否显示自定义的video缩略
            isCustomPice: {
              type: Boolean,
              default: false
            },
          // 上传文件的类型 当他是55的时候 需要切换成上传到海外仓服务器的key
          uploadFileType : {
            type : String,
            default : '0'
          },
          // 是否使用前端oss上传文件
          isUseOss: {
            type: Boolean,
            default: false
          },
        },
        data() {
            return {
              hideUploadBtn:false,
                imgUrl: '',
                visible: false,
              url:{
                deleteHetong:'/customer/file/delete',
                addhetong:'/customer/file/add',
                addSup:'/sys/supplierFile/add',
                delSup:'/sys/supplierFile/delete',
                uploadDataSave: '/sys/oss/file/uploadDataSave'
              },
              ossClient: null,
            }
        },
        created() {
          if(this.isUseOss) {

          }
          let jj = this;
          this.$nextTick(function () {
            // console.log('dom update complete',this.$refs);
            // this.$refs['form'].clearValidate();
            if (jj.limit && jj.fileList) {
              jj.hideUploadBtn = jj.fileList.length >= jj.limit;
              console.log('created $nextTick this.hideUploadBtn',jj.hideUploadBtn,jj.fileList,jj.limit)
            }
          })
        },
      mounted() {


      },
      methods: {
          handleEditChange(file,fileList){
            if (this.limit) {
              this.hideUploadBtn = fileList.length >= this.limit;
              console.log('this.hideUploadBtn',this.hideUploadBtn,this.fileList,this.limit)
            }
          },
            /** 初始化oss*/

            onexceed(){
                this.$message.warning("最多上传"+this.limit+"个文件哦,亲");
            },
          beforeUpload(file){
            //对文件大小进行限制
           const isLt2M = file.size / 1024 / 1024 < this.fileSizeLimit
            if (!isLt2M){
              this.$message.warning('文件大小不能超过' + this.fileSizeLimit + "MB")
            }
           const isFile = file.name.indexOf('.')!==-1;
            if (!isFile){
              this.$message.warning('该文件名不合法')
            }
            // const fileLnegth = file.name.length<=25;
            // if (!fileLnegth ){
            //     this.$message.warning('文件名长度最多25位')
            // }
            return isLt2M && isFile;
          },
            handleUpload(data) {
                console.log(data,'文件上传',this.isUseOss,this.uploadFileType);
                if(this.isUseOss) {
                  this.handleUploadOss(data);
                  return;
                }
                const formData = new FormData();
                // data.file.name = data.file.name.replace(/^[ ,，(\n" ")\t]+$/,'');
                formData.append('file', data.file);
                formData.append('dir', this.dir);
                postAction(this.action, formData, { showError: true }).then((res) => {
                  //如果传递参数为合同，则保留的是合同的id
                   if (this.baifang){
                    postAction(this.url.addhetong,{url:res.result.url,size:res.result.size + "KB",fileType:'3',cusId:this.baifang.cusId,name:res.result.name,ossId:res.result.id})
                        .then(resp=>{
                          res.result.baifang = resp.result.id;

                          this.$emit('add', this.fileFormatter(res.result))
                        })
                  } else {
                        this.$emit('add', this.fileFormatter(res.result))
                  }
                }).catch(err => {
                  if(err.code == '413') {
                    this.$message.warning("上传文件大小总和不能超过75MB");
                  }
                })
            },
            /**  阿里云OSS上传 */
            async handleUploadOss(request) {
              let uuid = this.generateUUID(6);
              let plate = this.plate;
              let dir = this.dir;
              if (!plate) {
                plate = 'fileUpload';
                dir = 'osOrder';
              }
              console.log("要上传的模块",plate,dir)
              const fileItem = await this.handleUploadOss2(request,plate,dir,uuid,'1', this.uploadFileType);
              console.log("上传返回的结果：" , fileItem);
              if (!fileItem) {
                that.$message.warning('上传文件失败');
                return false;
              }

              this.$emit('add', fileItem);
            },
            // 获取uuid
            generateUUID(length) {
              var d = new Date().getTime();
              let uuid = d.toString() + Math.random().toString().substr(3, length);
              return uuid;
            },
            handleRemove(file) {
                if (file && file.status==="success") {
                  const index = this.fileList.indexOf(file);
                  // 删除不删除原文件的特殊处理
                  if(this.isDetail === false) {
                    this.$emit('delete', index);
                    return;
                  }
                  if(file.id && file.id.length >0) {

                      const { id } = this.fileList[index]
                      deleteAction('/sys/oss/file/delete', { id }).then(res => {
                        if (this.baifang){
                          deleteAction(this.url.deleteHetong,{id:file.baifang})
                              .then(resp=>{
                                if (resp.success){
                                  this.$emit('delete', index)
                                  this.$message.success(res.message)
                                }
                              }).catch(err=>{
                              this.$emit('delete', index)
                          })

                        } else {
                          this.$emit('delete', index)
                          this.$message.success(res.message)
                        }

                      }).catch(err=>{
                          this.$emit('delete', index)
                      })
                    //如果传递参数为合同，则删除合同

                  }else {
                      this.$emit('delete', index);
                  }
                }
            },
            fileFormatter(data){
                let file = {
                    id: data.id,
                    name: data.name,
                    url: data.url,
                    size: data.size,
                  baifang:data.baifang,

                }
                return file
            },
             handlePreview(file) {
                  if (!file.url) {
                    this.$message.error('下载失败')
                    return
                  }

                  const type = file.url.substring(file.url.lastIndexOf('.')+1);
                  if (type === 'jpg' || type === 'jpeg' || type ==='img' || type === 'gif' || type === 'png' || type === 'pneg'){
                      this.imgUrl = file.url
                      this.visible = true
                  }
                  // 判断文件类型
                  else if (type === 'doc' || type === 'docx' || type === 'xlsx' || type === 'xls' || type === 'ppt' || type === 'pptx') {
                    // 在当前浏览器直接下载
                    document.location.href = file.url
                  } else {
                    // 图片在浏览器打开 新的页面展示
                    window.open(file.url, 'hello')
                  }
            },
            handleCancel() {
                this.visible = false;
            },
            /**
            * 根据文件url获取文件类型，返回image或者video
            * @param {*} url
            * @returns
            */
            getFileUrlType(url) {
              if (url) {
                let type = url.substring(url.lastIndexOf('.') + 1);
                let typeShow = '';
                if (['mp4', '3gp', 'avi', 'wmv', 'mpeg', 'mpg', 'mov', 'flv', 'swf', 'qsv', 'kux', 'mpg', 'rm', 'ram'].indexOf(type) !== -1) {
                  typeShow = 'video'
                } else if (['bmp', 'jpg', 'jpeg', 'jpe', 'jxr', 'png', 'tif', 'tiff', 'avif', 'xbm', 'pjp', 'svgz', 'ico', 'svg', 'jfif', 'webp', 'pjpeg', 'gif', 'iff', 'ilbm', 'ppm', 'pcx', 'xcf', 'xpm', 'psd', 'mng', 'sai', 'psp', 'ufo'].indexOf(type) !== -1) {
                  typeShow = 'image'
                }
                return typeShow;
              }
            }
        }
    }
</script>

<style lang="scss">
.isCustomPiceBox {
  .el-upload-list__item {
    width: 192px;
    height: 108px;
    div {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
